import axios from 'axios'
import store from '@/store'
import router from '@/router'

const qs = require('qs');


// DEV server strapi v4 = 'http://localhost:1338'

var serverStrapi = window.location.protocol + '//'+ window.location.hostname + ':1338'

//TEST or PROD server 'http://domain/strapi'

if (window.location.href.indexOf(':8080') < 0 && window.location.href.indexOf(':8000') < 0) {
  serverStrapi = window.location.protocol + '//'+ window.location.hostname + '/strapi'
} 

const server = serverStrapi + '/api';

//unlimited results
const defaultLimit = -1
//how many venue wines are per page
const paginationLimit = 50

//add default headers
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Accept'] = 'application/json';

// Add jwt auth token to request
// Add a no-cache header to request when in local dev environment
axios.interceptors.request.use(function (config) {
  if (config.url.includes('/auth/local') === false) {
    const token = 'Bearer ' + store.getters.getJwt;
    config.headers.Authorization =  token;
  }
  return config;
});

//delete session and redirect to login on forbidden error
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error)
  if (401 === error.response.status || 403 === error.response.status) {
    store.dispatch('logout')
    router.push('/login')
  } else {
    return Promise.reject(error);
  }
})

const populateVenue = {
  users: true,
  playlists: true,
  smartLists: true,
  currency: true,
  reports: true,
  wineries: true,
  taxRate: true,
  wineSuppliers: {
    populate: {
      address: true,
      venues: true,
    },
  }
}

const notDeletedVenueWinesFilter = {
  $or: [
    {
      deleted: {
        $null: true,
      },
    },
    {
      deleted: false,
    },
  ],
}
const isNotRarityFilter = {
  $or: [
    {
      rarity: {
        $null: true,
      },
    },
    {
      rarity: false,
    },
  ],
}


export default {

  //----------- API Utils -----------

  getPaginationLimit() {
    return paginationLimit
  },
  getServerUrl(){
    return server;
  },
  getRootServerUrl() {
    return serverStrapi;
  },
  isIntegrationServerOrLocalhost() {
    return serverStrapi.includes('integration.') || serverStrapi.includes('localhost')
  },

  /**
   * handle API errors like 401 FORBIDDEN and return callback function
   * with how to handle error
   * @param {*} error 
   */
   handleError(error, store, router, callback) {
    console.log(error)
    
    let errorResult = {
        'logout': false
    }

    if (error.response) {
      if (error.response['data'].data.statusCode === 401) {
        store.dispatch('logout')
        router.push('/login')
        errorResult.logout = true
      }
    }
    if (callback) {
      callback(errorResult)
    }
  },


  //-------------- API Endpoints ------------------------


  //************ Venue **************/

  getVenues () {
    return axios.get(server + '/venues/')
  },

  getVenueDetail (id) {

    const query = qs.stringify({
      populate: populateVenue
    }, {
      encodeValuesOnly: true,
    });
    
    if (id == null) {
      id = store.getters.getVenue.id
    }
    return axios.get(server + '/venues/' + id + '?' + query)
  },

  updateVenue (id, venue) {
    //remove unnecessary fields and construct updatable wine object
    const query = qs.stringify({
      populate: populateVenue
    }, {
      encodeValuesOnly: true,
    });

    const venueData = {
      data: {
        ...venue.attributes
      }
    }

    delete venueData.data.title
    delete venueData.data.createdAt
    delete venueData.data.updatedAt
    delete venueData.data.publishedAt
    delete venueData.data.revenueOptimizePercentLimit
    delete venueData.data.address
    delete venueData.data.venueWines
    delete venueData.data.image
    delete venueData.data.wines
    this.deleteVenueCache()
    return axios.put(server + '/venues/'+id + '?' + query, venueData)
  },
  /**
   * deletes whole cache-store
   * needs to be called after every post, put & delete to clear cache
   */
  deleteVenueCache () {
    const id = store.getters.getVenue.id
    return axios.delete(server + '/venues/cache/'+ id)
  },
 
  //************ VenueWine **************/

  /**
   * Get All Venue Wines
   * optional searchTerm can be added
   */
  getVenueWines (searchTerm) {
    var params = {
      filters: {
        venue: store.getters.getVenue.id,
        ...notDeletedVenueWinesFilter,
      },
      pagination: {
        limit: defaultLimit,
      }
    }

    if (searchTerm != null && searchTerm.length > 0) {
      params['q'] = searchTerm
    }

    const query = qs.stringify(params, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
  },

  getVenueWineDetail (id) {
    return axios.get(server + '/venue-wines/'+ id)
  },

  getVenueWinesSorted (sort = 'id', populateAll = false) {
    const filters = {
      venue: store.getters.getVenue.id,
    }
    const query = qs.stringify({
      filters: filters,
      sort: sort,
      pagination: {
        limit: defaultLimit,
      },
      populateAll: populateAll,
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/venue-wines/?' + query)
  },

  getVenueWinesFilter (filter) {
    var filters = {
      venue: store.getters.getVenue.id,
    }

    if (filter == 'offen') {
      filters = { ...filters, glassMode: true }
    } else if (filter == 'ausgetrunken') {
      filters = { ...filters, amount: 0 }
    }

    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
  },

  findVenueWine (wineId, year, bottleSizeId) {

    var filters = {
      venue: store.getters.getVenue.id,
      wine: {
        id: wineId,
      },
      year: year,
    }
    if (typeof bottleSizeId !== 'undefined') {
      filters = { ...filters, bottleSize: { id: bottleSizeId } }
    } 
    
    const query = qs.stringify({
      filters: filters
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
    
  },

  findVenueWineInVenueId (wineId, year, bottleSizeId, venueId) {
    const filters = {
      venue: venueId,
      wine: {
        id: wineId,
      },
      year: year,
      bottleSize: bottleSizeId
    }
    const query = qs.stringify({
      filters: filters,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
  },

  createVenueWine (wine) {
    var wineData = {
      data: {
        ...wine.attributes,
      } 
    }
    delete wineData.data.createdAt
    delete wineData.data.updatedAt
    this.deleteVenueCache()
    return axios.post(server + '/venue-wines/', wineData)
  },

  updateVenueWine (venueWineId, selectedWine) {
    //remove unnecessary fields and construct updatable wine object
    const wineData = {
      data: {
          ...selectedWine.attributes,
      }
    }
    delete wineData.data.createdAt
    delete wineData.data.updatedAt
    delete wineData.data.wine
    delete wineData.data.bottleSize
    delete wineData.data.venue
    this.deleteVenueCache()
    return axios.put(server + '/venue-wines/'+venueWineId, wineData)
  },

  //Venue Wine Charts and Widgets

  getVenueWinesChart (venueWineIds) {
    const filters = {
      venue: store.getters.getVenue.id,
    }
    let query = qs.stringify({
      filters: filters,
      pagination: {
        limit: defaultLimit,
      }
    }, {
      encodeValuesOnly: true,
    });
    return axios.post(server + '/venue-wines/chart/?' + query, venueWineIds)
  },

	/**
	 * Get Chart Data for VenueWines at a specific point of time in the past ("Ist-Bestand")
	 * 
	 * @param {*} venueWineIds 
	 * @param {*} dateString //in iso format like "2023-05-16T00:00:00Z"
	 * @returns 
	 */
	getVenueWinesHistoryChart (venueWineIds, dateString) {
    const historyFilters = {
      venue: store.getters.getVenue.id,
			createdAt: {
				$gt: dateString,
			}
    }
    let query = qs.stringify({
      filters: historyFilters,
      pagination: {
        limit: defaultLimit,
      }
    }, {
      encodeValuesOnly: true,
    });
    return axios.post(server + '/venue-wines/history-chart/?' + query, venueWineIds)
  },

  getRevenueDetailWidgetChart (from, to, historyTypeList, revenue) {
    const filters = {
      venue: store.getters.getVenue.id,
      $and: [
        { createdAt: { $gt: from } },
        { createdAt: { $lt: to } },
      ],
      revenue: revenue,
      $or: [],
    }

    historyTypeList.forEach(historyType => {
      filters.$or.push({
        type: historyType,
      })
    });

    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/widget-revenue-detail/?' + query)
  },

  getTopRevenueWidgetChart (from, to, chartLimit) {
    const filters = {
      venue: store.getters.getVenue.id,
      $and: [
        { createdAt: { $gt: from } },
        { createdAt: { $lt: to } },
      ],
      revenue: true,
      $or: [
        { type: 'change_amount_glasses' },
        { type: 'change_amount' },
      ],
    }

    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
      chartLimit: chartLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/widget-revenue-top/?' + query)
  },

  getRevenueOverTimeWidgetChart (from, to) {
    const filters = {
      venue: store.getters.getVenue.id,
      $and: [
        { createdAt: { $gt: from } },
        { createdAt: { $lt: to } },
      ],
      revenue: true,
      $or: [
        { type: 'change_amount_glasses' },
        { type: 'change_amount' },
      ],
    }
    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/widget-revenue-over-time/?' + query)
  },


  getActivitiesOverTime (from, to) {
    const filters = {
      venue: store.getters.getVenue.id,
      $and: [
        { createdAt: { $gt: from } },
        { createdAt: { $lt: to } },
      ],
    }
    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wine-histories/activities/?' + query)
  },

  getGoodsMovement (from, to) {
    const filters = {
      venue: store.getters.getVenue.id,
      $and: [
        { createdAt: { $gt: from } },
        { createdAt: { $lt: to } },
      ],
      $or: [
        { type: 'change_amount_glasses' },
        { type: 'change_amount' },
      ],
    }
    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wine-histories/goods-movements/?' + query)
  },
 

  // Venue Wine Insights

  getEmptySoonVenueWines(amount) {
    const filters = {
      venue: store.getters.getVenue.id,
      ...isNotRarityFilter,
      $and: [
        { amount: { $gt: 0 } },
        { amount: { $lte: amount } }
      ],
    }
    const query = qs.stringify({
      filters: filters,
      sort: 'amount:asc',
      pagination: {
        start: 0,
        limit: defaultLimit,
      }
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
  },

  getRevenueOptimizeWidget() {
    const filters = {
      venue: store.getters.getVenue.id,
    }
    const query = qs.stringify({
      filters: filters,
      pagination: {
        limit: defaultLimit,
      }
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/venue-wines/widget-revenue-optimize/?' + query)
  },

  /**
   * Get VenueWines filtered by api parameters for smart lists
   *  
   */
   findVenueWinesWithSmartListFilter(filterQuery, searchTerm) {
    filterQuery += '&filters[venue]=' + store.getters.getVenue.id
    
    if (searchTerm != null && searchTerm.length > 0) {
      filterQuery += '&q=' + searchTerm
    }


    return axios.get(server + '/venue-wines/?' + filterQuery + '&pagination[limit]='+defaultLimit)
  },

  /**
   * Counts VenueWines with PurchasePrices of WineSuppliers
   * 
   * @param {number[]} wineSupplierIds 
   * @returns {number} counted result
   */
  countVenueWinesOfWineSuppliers(wineSupplierIds) {
    const filters = {
      venue: store.getters.getVenue.id,
      purchasePrices: {
        wineSupplier: {
          id: {
            $in: wineSupplierIds,
          }
        }
      }
    }
    const query = qs.stringify({
      filters: filters,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wines/?' + query)
  },

  //************ VenueWineHistory **************/

  getVenueWineHistories () {
    const filters = {
      venue: store.getters.getVenue.id,
    }
    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wine-histories/?' + query)
  },

  findVenueWineHistory (venueWineId) {
    const filters = {
      venue: store.getters.getVenue.id,
      venueWine: {
        id: venueWineId,
      }
    }
    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wine-histories/logs/?' + query)
  },

  findVenueWineHistoryReports (filterQuery) {
    var filters = {
      venue: store.getters.getVenue.id,
    }

    const query = qs.stringify({
      filters: filters,
      limit: defaultLimit,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/venue-wine-histories/reports/?' + query + '&' + filterQuery)
  },

  //************ Wines **************/

  getWines () {
    const populate = {
      '0':'region',
      '1':'land',
      '2':'classification',
      '3':'grapeVariety',
      '4':'wineType',
      '5':'winery'
    }
    const query = qs.stringify({
      populate,
      pagination: {
        limit: defaultLimit,
      }
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wines?' + query)
  },
  /*
  getWinesForVenue (venueId, sort = 'id') {
    return axios.get(server + '/wines/venue/' + venueId + '/' + '?sort=' + sort )
  },*/
  getWine (id) {
    return axios.get(server + '/wines/'+id)
  },

  findWines (wine) {
    var filters = {
      title: wine.title,
      winery: wine.winery.id,
      region: wine.region.id,
      land: wine.land.id,
      grapeVariety: wine.grapeVariety.id,
      wineType: wine.wineType.id,
    }

    const query = qs.stringify({
      filters: filters,
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wines/?' + 'populate[0]=venue&' + query )
  },

  postWine (wine) {
    var postObject = {
      data: wine 
    }
    return axios.post(server + '/wines/?populate=*', postObject)
  },
  updateWine (wineId, selectedWine) {
    const wineData = {
      data: {
          ...selectedWine.attributes,
      }
    }
    delete wineData.data.createdAt
    delete wineData.data.updatedAt
    delete wineData.data.publishedAt
    this.deleteVenueCache()
    return axios.put(server + '/wines/' + wineId, wineData)
  },

  //************ Winery **************/

  getWineries (sorting = 'id') {
    const sort = sorting
    const populate = {
      '0':'region',
      '1':'land',
    }
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      populate,
      pagination
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wineries' + '?' + query)
  },
  getWineriesForVenue (venueId, sorting = 'id') {
    const sort = sorting
    const populate = {
      '0':'region',
      '1':'land',
    }
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      populate,
      pagination
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wineries/venue/' + venueId + '/' + '?'+ query)
  },
  getWinery (id) {
    return axios.get(server + '/wineries/'+id)
  },

	getWineryPopulated (id) {
		const populate = {
      wines: {
        populate: {
          venue: true,
          region: true,
          land: true,
          wineType: true,
          grapeVariety: true
        }
      },
      region: true,
      land: true,
      venue: true,
    }

    const query = qs.stringify({
      populate: populate
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/wineries/'+id + '/' + '?' + query)
  },

  findWinery (winery) {
    const populate = {
      '0':'region',
      '1':'land',
      '2':'venue'
    }
    const filters = {
      title: winery.title,
      region: winery.region.id,
      land: winery.land.id,
    }

    const query = qs.stringify({
      filters: filters,
      populate
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/wineries?' + query)
  },

  /**
   * Search for Wineries with title
   * @param {*} title 
   * @returns 
   */
  searchWinery (title) {
    const filters = {
      title: {
        $contains: title
      }
    }
    const populate = {
      wines: {
        populate: {
          venue: true,
          region: true,
          land: true,
          wineType: true,
          grapeVariety: true
        }
      },
      region: true,
      land: {
        populate: {
          grape_varieties: {
            sort: 'title:asc',
            populate: {
              wine_types: true,
            }
          }
        },
        
      },
      venue: true,
    }

    const query = qs.stringify({
      filters: filters,
      populate: populate
    }, {
      encodeValuesOnly: true,
    });

    const searchWinery = axios.get(server + '/wineries?' + query)

    return searchWinery
  },
  postWinery (wineryPostModel) {
    this.deleteVenueCache()
    return axios.post(server + '/wineries/?populate=*', wineryPostModel)
  },
  updateWinery (wineryId, selectedWinery) {
    const wineryData = {
      data: {
          ...selectedWinery.attributes,
      }
    }
    delete wineryData.data.createdAt
    delete wineryData.data.updatedAt
    delete wineryData.data.publishedAt
    this.deleteVenueCache()
    return axios.put(server + '/wineries/' + wineryId, wineryData)
  },
 
  //************ Region **************/

  getAllCuratedRegions(sorting = 'id') {
    const sort = sorting
    const filters = {
      curated: true
    }
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      filters,
      pagination
    }, {
      encodeValuesOnly: true,
    })
   
    return axios.get(server + '/regions/' + '?' + query )
  },
  getRegionsForVenue (venueId, sort = 'id') {
    return axios.get(server + '/regions/venue/' + venueId + '/' + '?sort=' + sort )
  },
  getRegion (id) {
    return axios.get(server + '/regions/'+id)
  },
  findRegion (title) {
    return axios.get(server + '/regions?filters[title]='+ title)
  },
  postRegion (region) {
    var postObject = {
      data: region 
    }
    this.deleteVenueCache()
    return axios.post(server + '/regions/', postObject)
  },
  putRegion (region) {
    var postObject = {
      data: region 
    }
    this.deleteVenueCache()
    return axios.put(server + '/regions/'+region.id, postObject)
  },

  //************ Land **************/

  getAllLands(sorting = 'id') {
    const sort = sorting
    const populate = {
      regions: true,
      grape_varieties: {
        sort: 'title:asc',
        populate: {
          wine_types: true,
        }
      }
    }
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      pagination,
      populate
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/lands/' + '?' + query )
  },
  getLandsForVenue (venueId, sort = 'id') {
    return axios.get(server + '/lands/venue/' + venueId + '/' + '?sort=' + sort )
  },
  getLand (id) {
    return axios.get(server + '/lands/'+id)
  },
  findLand (title) {
    return axios.get(server + '/lands?filters[title]='+ title)
  },
  postLand (land) {
    var postObject = {
      data: land 
    }
    this.deleteVenueCache()
    return axios.post(server + '/lands/', postObject)
  },

  //************ Classification **************/

  getClassification (id) {
    return axios.get(server + '/classifications/'+id)
  },
  getClassificationsForVenue (venueId, sort = 'id') {
    return axios.get(server + '/classifications/venue/' + venueId + '/' + '?sort=' + sort)
  },
  findClassification (title) {
    return axios.get(server + '/classifications?filters[title]='+ title)
  },
  postClassification (cla) {
    this.deleteVenueCache()
    return axios.post(server + '/classifications/', JSON.stringify(cla))
  },

  //************ GrapeVariety **************/

  getAllCuratedGrapeVarieties(sorting = 'id') {
    const sort = sorting
    const filters = {
      curated: true
    }
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      filters,
      pagination
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/grape-varieties/' + '?' + query)
  },
  getGrapeVarietiesForVenue (venueId, sorting = 'id') {
      const sort = sorting
     
      const pagination = {
        limit: defaultLimit,
      }
      const query = qs.stringify({
        sort,
        pagination
      }, {
        encodeValuesOnly: true,
      });
    return axios.get(server + '/grape-varieties/venue/' + venueId + '/' + '?' + query)
  },
  getGrapeVariety (id) {
    return axios.get(server + '/grape-varieties/'+id)
  },
  findGrapeVariety (title) {
    const populate = {
      wine_types: true,
      lands: true
    }
    const filters = {
      title: title
    }
    const query = qs.stringify({
      populate,
      filters
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/grape-varieties?'+query)
  },
  postGrapeVariety (cla) {
    const body = qs.stringify({
      data: cla
    }, {
      encodeValuesOnly: true,
    });
    this.deleteVenueCache()
    return axios.post(server + '/grape-varieties/', body)
  },
  putGrapeVariety (grape) {
    const body = qs.stringify({
      data: grape
    }, {
      encodeValuesOnly: true,
    });
    this.deleteVenueCache()
    return axios.put(server + '/grape-varieties/'+grape.id, body)
  },
  //************ WineType **************/

  getAllWineTypes (sorting = 'id') {
    const sort = sorting

    const populate = {
      '0':'grape_varieties'
    }
     
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      populate,
      pagination
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wine-types/' + '?' + query)
  },
  getWineTypesForVenue (venueId, sorting = 'id') {
    const sort = sorting

    const populate = {
      '0':'grape_varieties'
    }
     
    const pagination = {
      limit: defaultLimit,
    }
    const query = qs.stringify({
      sort,
      populate,
      pagination
    }, {
      encodeValuesOnly: true,
    });
    return axios.get(server + '/wine-types/venue/' + venueId + '/' + '?' + query)
  },
  getWineType (id) {
    return axios.get(server + '/wine-types/'+id)
  },
  findWineType (title) {
    return axios.get(server + '/wine-types?filters[title]='+title)
  },
  postWineType (cla) {
    const body = qs.stringify({
      data:  cla
    }, {
      encodeValuesOnly: true,
    });
    this.deleteVenueCache()
    return axios.post(server + '/wine-types/', body)
  },

  //************ Playlists **************/

  getPlaylist (playlistId) {
    return axios.get(server + '/playlists/'+ playlistId)
  },

  /**
   * 
   * @param {*} playlistId 
   * @param {*} searchTitle 
   * @param {*} start 
   * @param {*} limit 
   * @returns 
   */
  getPlaylistWithFilter (playlistId, searchTitle) {
    const filters = {
      wineFilterTitle: searchTitle,
    }
    const query = qs.stringify({
      filters: filters,
    }, {
      encodeValuesOnly: true,
    });

    return axios.get(server + '/playlists/' + playlistId + '/?' + query)
  },

  createPlaylist (playlist) {
    const data = {
      data: { ...playlist.attributes },
    }
    this.deleteVenueCache()
    return axios.post(server + '/playlists/', data)
  },

  updatePlaylist (id, playlist) {
    const title = playlist.attributes.title
    let data = {
      data: {
        title: title,
      },
    }
    if ('venueWines' in playlist.attributes) {
      const venueWines = playlist.attributes.venueWines.data
      const venueWineIds = venueWines.map(wine => {
        if (wine instanceof Object) {
          return wine.id.toString()
        } else {
          return wine
        }
      }) 
      data.data.venueWines = venueWineIds
    }
    this.deleteVenueCache()
    return axios.put(server + '/playlists/' + id, data)
  },

  deletePlaylist (id) {
    this.deleteVenueCache()
    return axios.delete(server + '/playlists/' + id)
  },

  deleteVenueWineFromPlaylist(venueWineId, playlistId) {
    const body = qs.stringify({
      data: {
        venueWineId : venueWineId,
      }
    }, {
      encodeValuesOnly: true,
    });
    this.deleteVenueCache()
    return axios.post(server + '/playlists/delete-venue-wine/'+playlistId, body)
  },

  //************ Smart Lists **************/
  getSmartList (id) {
    return axios.get(server + '/smart-lists/'+ id)
  },
  createSmartList (smartList) {
    const data = {
      data: {
         ...smartList
      },
    }
    this.deleteVenueCache()
    return axios.post(server + '/smart-lists/', data)
  },
  deleteSmartList (id) {
    this.deleteVenueCache()
    return axios.delete(server + '/smart-lists/' + id)
  },
  updateSmartList (id, smartList) {
    let data = {
      data: {
        ...smartList
      }
    }
    this.deleteVenueCache()
    return axios.put(server + '/smart-lists/' + id, data)
  },

  //************ Reports ******************/
  
  findReports() {
    return axios.get(server + '/reports/?filters[venue]=' + store.getters.getVenue.id )
  },
  getReport(id) {
    return axios.get(server + '/reports/'+ id)
  },
  createReport (report) {
    const data = {
      data: {
         ...report
      }
    }
    this.deleteVenueCache()
    return axios.post(server + '/reports/', data)
  },
  deleteReport (id) {
    this.deleteVenueCache()
    return axios.delete(server + '/reports/' + id)
  },
  updateReport (id, report) {

    let data = {
      data:{
        ...report
      }
    }
    this.deleteVenueCache()
    return axios.put(server + '/reports/' + id, data)
  },

  //************ WineSupplier **************/

  getAllWineSuppliers(venueId, sort = 'id') {
    return axios.get(server + '/wine-suppliers/?filters[venues][id]=' + venueId + '&sort=' + sort )
  },
  getWineSupplier(id) {
    return axios.get(server + '/wine-suppliers/' + id + '?filters[venues][id]=' + store.getters.getVenue.id + '&populate=*' )
  },
  createWineSupplier (wineSupplierBody) {
    let data = {
      data: {
        title: wineSupplierBody.attributes.title,
        email: wineSupplierBody.attributes.email,
        address: {
          city: wineSupplierBody.attributes.address.city,
          zip: wineSupplierBody.attributes.address.zip,
        },
        venues: [store.getters.getVenue.id],
      }
    }
    this.deleteVenueCache()
    return axios.post(server + '/wine-suppliers/', data)
  },
  updateWineSupplier (id, wineSupplierBody) {
    var venueIds = ( wineSupplierBody.attributes.venues.data ?? [] ).map((venue) => venue.id)
    venueIds.push(store.getters.getVenue.id)
    venueIds = [...new Set(venueIds)]

    let data = {
      data: {
        ...wineSupplierBody.attributes,
        venues: venueIds,
      },
    }
    this.deleteVenueCache()
    return axios.put(server + '/wine-suppliers/'+ id + '?filters[venues][id]=' + store.getters.getVenue.id, data)
  },

  deleteWineSupplier (id, venueId) {
    this.deleteVenueCache()
    return axios.delete(server + '/wine-suppliers/' + id + '?filters[venues][id]=' + venueId)
  },

  findWineSupplier (title) {
    return axios.get(server + '/wine-suppliers/?filters[title]=' + title + '&filters[venues][id]=' + store.getters.getVenue.id)
  },

  //************ EMAIL ***********************/
  sendMail (text) {
    var body = { text: text}
    this.deleteVenueCache()
    return axios.post(server + '/emails', JSON.stringify(body))
  },

  //************ Bottles ***********************/

  getBottleSizes () {
    return axios.get(server + '/bottle-sizes?sort=amount')
  },
  getBottleSizesForVenue (venueId, sort = 'id') {
    return axios.get(server + '/bottle-sizes/venue/' + venueId + '/' + '?sort=' + sort )
  },
  findBottleSize (amount) {
    return axios.get(server + '/bottle-sizes/?amount='+amount)
  },
  createBottleSize(bottleSize) {
    this.deleteVenueCache()
    return axios.post(server + '/bottle-sizes/', JSON.stringify(bottleSize))
  },

  //************ Currencies ***********************/

  getCurrencies () {
    return axios.get(server + '/currencies')
  },
  //************ TaxRates ***********************/

  getTaxRates () {
    return axios.get(server + '/tax-rates')
  },

    //************ rating Agencies ***********************/

    getRatingAgencies () {
      return axios.get(server + '/rating-agencies')
    },
  
    //************ Languages ***********************/
  getLanguages () {
      return axios.get(server + '/languages')
    },

    //************ Inventory ***********************/
    getInventoryUsers () {
      return axios.get(server + '/inventory-users?populate=*')
    },
    postInventoryUser(inventoryUser) {
      const inventoryUserObject = {
        data: inventoryUser
      }
      return axios.post(server + '/inventory-users/', inventoryUserObject)
    },
    postInventory(inventory) {
      const inventoryObject = {
        data: inventory
      }
      return axios.post(server + '/inventories/', inventoryObject)
    },
    getInventories() {
      var params = {
        filters: {
          venue: store.getters.getVenue.id,
        },
        pagination: {
          limit: defaultLimit,
        },
      }
      const query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
      return axios.get(server + '/inventories/?' + query)
    },
    getInventory(id) {
      var params = {
        filters: {
          venue: store.getters.getVenue.id,
        },
        pagination: {
          limit: defaultLimit,
        }
      }
      
      const query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
      return axios.get(server + '/inventories/' + id + '?' + query)
    },
    updateInventoryVenueWine(inventoryVenueWineId, selectedInventoryVenueWine) {
      const inventoryVenueWineData = {
        data: {
          ...selectedInventoryVenueWine,
        }
      }
      delete inventoryVenueWineData.data.createdAt
      delete inventoryVenueWineData.data.updatedAt
      delete inventoryVenueWineData.data.inventory
      delete inventoryVenueWineData.data.venueWine
      return axios.put(server + '/inventory-venue-wines/' + inventoryVenueWineId, inventoryVenueWineData)
    },
    createInventoryVenueWine (inventoryVenueWine) {
      var postObject = {
        data: inventoryVenueWine 
      }
      this.deleteVenueCache()
      return axios.post(server + '/inventory-venue-wines/', postObject)
    },
    deleteInventory(id) {
      this.deleteVenueCache()
      return axios.delete(server + '/inventories/' + id)
    },
    updateInventory(id, inventory) {
      const inventoryData = {
        data: {
            ...inventory,
        }
      }
      delete inventoryData.data.createdAt
      delete inventoryData.data.updatedAt
      delete inventoryData.data.publishedAt
      this.deleteVenueCache()
      return axios.put(server + '/inventories/' + id, inventoryData)
    },
    transmitInventory(id) {
      var params = {
        filters: {
          venue: store.getters.getVenue.id,
        }
      }
    
      const query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
      return axios.put(server + '/inventory/transmit/' + id + '?'+ query, {})
    },
    finishInventory(id, date) {
      var params = {
        filters: {
          venue: store.getters.getVenue.id,
        }
      }
      
      const query = qs.stringify(params, {
        encodeValuesOnly: true,
      });
      return axios.put(server + '/inventory/finish/' + id + '?'+ query, {
        date: date
      })
    },
  
    
  //************ User Data***********************/

  updateUserData (user) {
    this.deleteVenueCache()
    return axios.put(server + '/users/me/', user)
  },
  getUserData () {
    return axios.get(server + '/users/me/?populate=*')
  },
  login (email, password) {
    return axios.post(server +'/auth/local?populate=*', {
      identifier: email,
      password: password
  }) 
  },

  //************ SEARCH ***********************/
  searchVenueWines (searchTerm, venueWineAttributes) {
    return axios.post(server + '/search/venuewines?s='+searchTerm+'&venueId='+ store.getters.getVenue.id, {venueWineAttributes: venueWineAttributes})
  }
}
