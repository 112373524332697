import store from '@/store'

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
  
        next()
        return
            
    }
    next('/login')
    return
}

const ifAuthenticatedAndVenueIdChosen = (to, from, next) => {
  
    if (store.getters.isAuthenticated) {
      if (to.meta.roles == null || to.meta.roles.indexOf(store.getters.getUser.role.name) > -1) {
        if (store.getters.venueChosen) {
            next()
            return
                
        } else {
           
            next('/venues')
            return
        } 
      }
    }
    next('/login')
}

export default [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated'],
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/venues',
        name: 'VenueChooser',
        component: () => import('@/views/VenueChooser.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
          roles: ['Authenticated', 'InventoryOnly'],
        },
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword.vue'),
    },
    {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue'),
    },
    {
        path: '/wines',
        name: 'Wines',
        component: () => import('@/views/VenueWines.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        props: (route) => ({  playlistId: route.query.playlistId, smartListId: route.query.smartListId }),
        meta: {
          roles: ['Authenticated'],
        },
    },
    {
        path: '/csv',
        name: 'CSV',
        component: () => import('@/views/CSV.vue'),
        meta: {
          roles: ['Authenticated'],
        }
    },
    {
      path: '/updateAmounts',
      name: 'AmountCSV',
      component: () => import('@/views/UpdateAmounts.vue'),
      meta: {
        roles: ['Authenticated'],
      },
    },
    {
        path: '/insights',
        name: 'Insights',
        component: () => import('@/views/Insights.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated'],
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: () => import('@/views/Reports.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated'],
        },
    },
    {
        path: '/winecard',
        name: 'Weinkarte',
        component: () => import('@/views/Winecard.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated'],
        },
    },
    {
        path: '/inventories',
        name: 'Inventories',
        component: () => import('@/views/Inventories.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated', 'InventoryOnly'],
        },
    },
    {
        path: '/inventories/inventory/:id?',
        name: 'Inventory',
        component: () => import('@/views/Inventory.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen,
        meta: {
          roles: ['Authenticated', 'InventoryOnly'],
        },
    },
    {
      path: '/inventories/inventory/:id?/wines/:filterString?',
      name: 'InventoryWines',
      component: () => import('@/views/InventoryWines.vue'),
      beforeEnter : ifAuthenticatedAndVenueIdChosen,
      meta: {
        roles: ['Authenticated', 'InventoryOnly'],
      },
    },
    {
      path: '/updater',
      name: 'Updater',
      component: () => import('@/views/Updater.vue'),
      beforeEnter : ifAuthenticatedAndVenueIdChosen,
      meta: {
        roles: ['Authenticated'],
      },
    }
    /*
    {
        path: '/wine/:id?',
        name: 'WineDetail',
        component: () => import('@/views/WineDetail.vue'),
        beforeEnter : ifAuthenticatedAndVenueIdChosen
    }, */
]